import { Utils } from '../utils';
const utils = new Utils();

const getContentBodyFrom = (iframe) => {
    if (!iframe.contentDocument || !iframe.contentDocument.body) {
        return { scrollHeight: 0, scrollWidth: 0 };
    }
    return iframe.contentDocument.body;
};

export default class AppIframe {
    constructor(src, isSkyScraper) {
        const iframe = document.createElement('iframe');
        iframe.src = src;
        iframe.id = 'wato-iframe';
        iframe.scrolling = 'no';
        iframe.frameBorder = '0';
        iframe.style.visibility = 'hidden';
        iframe.onload = this.createOnloadHandler(isSkyScraper);

        this.onSuccess = () => {};
        this.onFailure = () => {};
        this.iframe = iframe;
    }

    detachFrom(anchor) {
        if (!this.iframe) {
            utils.debugLog(
                'AppIframe.detachFrom failed: AppIframe not initialized'
            );
            return;
        }
        utils.debugLog('AppIframe.detachFrom');
        anchor.removeChild(this.iframe);
    }

    appendTo(anchor) {
        if (!this.iframe) {
            utils.debugLog(
                'AppIframe.appendTo failed: AppIframe not initialized'
            );
            return;
        }
        utils.debugLog('AppIframe.appendTo');
        anchor.appendChild(this.iframe);
    }

    prependTo(anchor) {
        if (!this.iframe) {
            utils.debugLog(
                'AppIframe.prependTo failed: AppIframe not initialized'
            );
            return;
        }
        utils.debugLog('AppIframe.prependTo');
        anchor.prepend(this.iframe);
    }

    getReference() {
        return document.getElementById(this.iframe.id);
    }

    getContentWidth(iframe) {
        return getContentBodyFrom(iframe).scrollWidth + 'px';
    }

    getContentHeight(iframe) {
        return getContentBodyFrom(iframe).scrollHeight + 'px';
    }

    tearDownIframe() {
        const iframeRef = this.getReference();
        iframeRef.parentNode.removeChild(iframeRef);
        this.iframe = null;
    }

    isIframeLoaded() {
        const iframeRef = this.getReference();
        return !(
            !iframeRef.contentDocument ||
            !iframeRef.contentDocument.getElementById('wato-content')
        );
    }

    createOnloadHandler(isSkyScraper) {
        return () => {
            if (!this.isIframeLoaded()) {
                this.tearDownIframe();
                this.onFailure();
            } else {
                const iframeRef = this.getReference();
                this._setInitialConfig(iframeRef, isSkyScraper);
                this.onSuccess(iframeRef);

                iframeRef.style.visibility = 'visible';
            }
        };
    }

    _setInitialConfig(iframeRef, isSkyScraper) {
        iframeRef.contentDocument.body.style.margin = '0';
        if (isSkyScraper) {
            iframeRef.style.height = '600px';
            iframeRef.style.width = '160px';
            return;
        }
        iframeRef.style.height = this.getContentHeight(iframeRef);
        iframeRef.style.width = this.getContentWidth(iframeRef);
    }
}

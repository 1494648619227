import {Utils} from "./utils";

const utils = new Utils();

// bootstrap application
function bootstrap(odinAdService) {
  window.o_global.eventLoader.onLoad(30, () => {
      const deviceType = getDeviceType();

      if (deviceType === 'mobile') {
        // for mobile we need to wait for the MAS to finish loading :-/ this is bad.
        setTimeout(() => {
          odinAdService.renderAd(deviceType)
        }, 1000);
      } else {
        odinAdService.renderAd(deviceType)
      }
  });
}

function getDeviceType() {
  if (!utils.isCurrentWindowWideEnough(window)) {
    return "mobile";
  }
  return "desktop";
}

export {bootstrap}

class TrackingApi {
    constructor(utils) {
        this.utils = utils;
    }

    sendEventRequest(payload) {
        if (this._isTrackingBctAvailable()) {
            window.o_tracking.bct.sendEventToTrackingServer(payload);
            this.utils.debugLog('wato event tracking', payload);
        }
    }

    sendEventMergeRequest(payload, eventMergeId) {
        if (this._isTrackingBctAvailable()) {
            window.o_tracking.bct.sendEventMergeToTrackingServer(payload, eventMergeId);
            this.utils.debugLog('wato event merge tracking', payload, eventMergeId);
        }
    }

    sendMergeRequest(payload) {
        if (this._isTrackingBctAvailable()) {
            window.o_tracking.bct.sendMergeToTrackingServer(payload);
            this.utils.debugLog('wato merge tracking', payload);
        }
    }

    trackOnNextPageImpression(payload) {
        if (this._isTrackingBctAvailable()) {
            window.o_tracking.bct.trackOnNextPageImpression(payload);
            this.utils.debugLog('wato tracking event on next page impression', payload);
        }
    }

    _isTrackingBctAvailable() {
        return window.o_tracking && window.o_tracking.bct;
    }

}

export {TrackingApi};

import {bootstrap} from "./bootstrap";
import AdvertService from "./service/advertService";
import {TrackingApi} from "./trackingApi";
import {Utils} from "./utils";
import { ContextualInformationService } from "./service/contextualInformationService";

const trackingApi = new TrackingApi(new Utils());
const contextualInformationService = new ContextualInformationService();

bootstrap(new AdvertService(trackingApi, contextualInformationService));

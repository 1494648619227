class ContextualInformationService {
    /**
     * Right now the consumer of our WATO Service cannot provide us with the context information. So we need a
     * workaround to get the context information from the page - more precisely from the playout of a SDA, which
     * adds the data to the window object.
     *
     * @returns {{brandgroup: string, produktbasisklasse: string, assortmentNavigation: string}|null}
     */
    retrieve() {
        if (window.o_sda === undefined) {
            return null;
        }

        if (window.o_sda.contextualInformation === undefined) {
            return null;
        }

        return window.o_sda.contextualInformation;
    }
}

export { ContextualInformationService };
